<template>
  <a-card class="card" :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="GLOBAL.queryRowGutter">
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="车牌号码">
              <a-input v-model="queryParam.plate_number"/>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <div class="table-operator">
      <a-button type="query" icon="search" @click="$refs.table.refresh(true)">查询</a-button>
      <a-button type="primary" icon="plus" @click="handleCreate">新建</a-button>
      <a-button type="danger" icon="delete" @click="handleDelete('all')" :disabled="!this.selectedRowKeys.length">删除</a-button>
    </div>

    <s-table
      ref="table"
      :scroll="{ x: 800 }"
      rowKey="id"
      :columns="columns"
      :data="loadData"
      :rowSelection="{ selectedRowKeys: this.selectedRowKeys, onChange: this.onSelectChange }"
      @dblclick="handleEdit">
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <span slot="type" slot-scope="text">
        {{ typeMap[text] && typeMap[text]['name'] }}
      </span>
      <span slot="action" slot-scope="text, record, index">
        <template>
          <a @click="handleEdit(record, index)">编辑</a>
          <a-divider type="vertical" />
          <a @click="handleDelete('row', record)">删除</a>
        </template>
      </span>
    </s-table>

    <a-modal
      :visible="formVisible" :confirmLoading="confirmLoading"
      :title="modalFlag === 'create' ? '创建车辆' : '编辑车辆'"
      :width="720" @cancel="formVisible = false"
      @ok="handleSubmit"
    >
      <a-form-model layout="vertical" :model="form"
                    :rules="rules" ref="form">
        <a-row :gutter="20">
          <a-col :span="8">
            <a-form-model-item label="车牌号码" prop="plate_number">
              <a-input v-model="form.plate_number" @change="handlePlateChange"/>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="简码">
              <a-input v-model="form.abbr" />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="所属司机">
              <a-select v-model="form.driver_id" showSearch
                        ref="driver"
                        allowClear style="width: 100%">
                <a-select-option v-for="o in driverOps" :key="o.value">{{ o.name }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="20">
          <a-col :span="8">
            <a-form-model-item label="所属车队">
              <a-select v-model="form.fleet_id" showSearch
                        ref="fleet"
                        allowClear style="width: 100%">
                <a-select-option v-for="o in fleetOps" :key="o.value">{{ o.name }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="经营方式">
              <a-select v-model="form.type" showSearch
                        allowClear style="width: 100%">
                <a-select-option v-for="item in typeMap" :key="item.value">{{ item.name }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="年审有效期">
              <a-date-picker v-model="form.year_review_expiry" class="full-width"/>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="20">
          <a-col :span="8">
            <a-form-model-item label="行驶证号">
              <a-input v-model="form.license_number" />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="驾驶证有效期">
              <a-date-picker v-model="form.license_expiry" class="full-width" />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="营运证号">
              <a-input v-model="form.operate_certificate" />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="20">
          <a-col :span="8">
            <a-form-model-item label="营运证有效期">
              <a-date-picker v-model="form.operate_certificate_expiry" class="full-width" />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="商业险单号">
              <a-input v-model="form.insurance_order_num" />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="商业险有效期">
              <a-date-picker v-model="form.insurance_expiry" class="full-width" />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="20">
          <a-col :span="8">
            <a-form-model-item label="提前提醒天数">
              <a-input-number v-model="form.pre_remind_days" class="full-width" />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="挂车行驶证有效期">
              <a-date-picker v-model="form.trailer_license_expiry" class="full-width" />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-form-model-item label="备注">
          <a-textarea v-model="form.remark" placeholder="" :rows="3" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </a-card>
</template>

<script>
import { STable } from '@/components'
import moment from 'moment';
import { getMetaInfoPage, getMetaOptions, deleteMetaTypeInfo, createMetaTypeInfo, updateMetaTypeInfo } from '@/api/wms/meta'
export default {
  components: {
    STable
  },
   data() {
    return {
      queryParam: {},
      typeMap: {
        1: { value: 1, name: '内管车' },
        2: { value: 2, name: '外牌车' },
        3: { value: 3, name: '挂靠车' }
      },
      columns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' },
          width: 30
        },
        {
          title: '车牌号码',
          dataIndex: 'plate_number',
          width: 100
        },
        {
          title: '简码',
          dataIndex: 'abbr',
          width: 100
        },
        {
          title: '所属司机',
          dataIndex: 'driver_name',
          width: 80
        },
        {
          title: '所属车队',
          dataIndex: 'fleet_name',
          width: 120
        },
        {
          title: '经营方式',
          dataIndex: 'type',
          scopedSlots: { customRender: 'type' },
          width: 100
        },
        {
          title: '年审有效期',
          dataIndex: 'year_review_expiry',
          width: 120
        },
        {
          title: '行驶证号',
          dataIndex: 'license_number',
          width: 120
        },
        {
          title: '行驶证有效期',
          dataIndex: 'license_expiry',
          width: 120
        },
        {
          title: '营运证号',
          dataIndex: 'operate_certificate',
          width: 120
        },
        {
          title: '营运证有效期',
          dataIndex: 'operate_certificate_expiry',
          width: 120
        },
        {
          title: '商业险单号',
          dataIndex: 'insurance_order_num',
          width: 120
        },
        {
          title: '商业险有效期',
          dataIndex: 'insurance_expiry',
          width: 120
        },
        {
          title: '提前提醒天数',
          dataIndex: 'pre_remind_days',
          width: 120
        },
        {
          title: '提醒人',
          dataIndex: 'remind_user',
          width: 80
        },
        {
          title: '挂车行驶证有效期',
          dataIndex: 'trailer_license_expiry',
          width: 120
        },
        {
          title: '备注',
          dataIndex: 'remark',
          width: 120
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: '120px',
          fixed: 'right',
          scopedSlots: { customRender: 'action' }
        }
      ],
      selectedRowKeys: [],
      loadData: parameter => {
        return getMetaInfoPage('vehicle', Object.assign(parameter, this.queryParam))
          .then(res => {
            return res
          })
      },
      fleetOps: [],
      driverOps: [],
      form: {
        plate_number: '',
        abbr: '',
        driver_id: '',
        driver_name: '',
        fleet_id: '',
        fleet_name: '',
        type: 3,
        year_review_expiry: null,
        license_number: '',
        license_expiry: null,
        operate_certificate: '',
        operate_certificate_expiry: null,
        insurance_order_num: '',
        insurance_expiry: null,
        pre_remind_days: '',
        trailer_license_expiry: null,
        remark: ''
      },
      rules: {
        plate_number: [
          { required: true, message: '请输入车牌号', trigger: 'blur' }
        ]
      },
      currentData: null,
      formVisible: false,
      confirmLoading: false,
      modalFlag: 'create'
    }
  },
  methods: {
    handleCreate() {
      this.modalFlag = 'create'
      this.currentData = null
      this.form = {
        plate_number: '',
        abbr: '',
        driver_id: '',
        driver_name: '',
        fleet_id: '',
        fleet_name: '',
        type: 3,
        year_review_expiry: null,
        license_number: '',
        license_expiry: null,
        operate_certificate: '',
        operate_certificate_expiry: null,
        insurance_order_num: '',
        insurance_expiry: null,
        pre_remind_days: '',
        trailer_license_expiry: null,
        remark: ''
      }
      this.getFormBasicData()
      this.formVisible = true
      this.$nextTick(_ => {
        console.log(this.form)
        this.$refs.form.resetFields();
      })
    },
    handlePlateChange() {
      const _num = this.form.plate_number
      const index = _num.search('[0-9]')
      console.log(index)
      this.form.abbr = index > -1 ? _num.substr(index) : ''
    },
    handleEdit(record) {
      this.modalFlag = 'update'
      this.getFormBasicData()
      this.formVisible = true
      this.currentData = record
      this.form = { ...record }
      this.form.year_review_expiry = record.year_review_expiry ? moment(record.year_review_expiry, 'YYYY/MM/DD') : null
      this.form.license_expiry = record.license_expiry ? moment(record.license_expiry, 'YYYY/MM/DD') : null
      this.form.operate_certificate_expiry = record.operate_certificate_expiry ? moment(record.operate_certificate_expiry, 'YYYY/MM/DD') : null
      this.form.insurance_expiry = record.insurance_expiry ? moment(record.insurance_expiry, 'YYYY/MM/DD') : null
      this.form.trailer_license_expiry = record.trailer_license_expiry ? moment(record.trailer_license_expiry, 'YYYY/MM/DD') : null
    },
    getFormBasicData() {
      getMetaOptions('fleet').then(v => {
        this.fleetOps = v
      })
      getMetaOptions('driver').then(v => {
        this.driverOps = v
      })
    },
    handleDelete(flag, record) {
      this.$confirm({
        title: '确认提示',
        content: '确定要删除？',
        onOk: () => {
          let _ids = []
          if (flag === 'all') {
            _ids = this.selectedRowKeys
          } else {
            _ids = [record.id]
          }
          deleteMetaTypeInfo('vehicle', { ids: _ids }).then(_ => {
            this.$notification['success']({
              message: '提示',
              description: '删除成功.'
            })
            this.selectedRowKeys = [];
            this.$refs.table.refresh(true)
          })
        }
      })
    },
    handleSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          let func = createMetaTypeInfo
          if (this.modalFlag === 'update') {
            func = updateMetaTypeInfo
          }
          const data = { ...this.form,
            fleet_name: this.$refs['fleet'].$el.innerText,
            driver_name: this.$refs['driver'].$el.innerText,
            license_expiry: this.form.license_expiry ? moment(this.form.license_expiry).format('L') : null,
            year_review_expiry: this.form.year_review_expiry ? moment(this.form.year_review_expiry).format('L') : null,
            operate_certificate_expiry: this.form.operate_certificate_expiry ? moment(this.form.operate_certificate_expiry).format('L') : null,
            insurance_expiry: this.form.insurance_expiry ? moment(this.form.insurance_expiry).format('L') : null,
            trailer_license_expiry: this.form.trailer_license_expiry ? moment(this.form.trailer_license_expiry).format('L') : null
          }
          if (this.currentData) {
            Object.assign(this.currentData, data)
          }
          func('vehicle', data).then(_ => {
              this.confirmLoading = false
              this.formVisible = false
              this.$notification['success']({
                message: '提示',
                description: this.modalFlag === 'update' ? '编辑成功' : '创建成功.'
              })
              if (this.modalFlag === 'create') {
                this.$refs.table.refresh(true)
              }
            }).catch(_ => {
              this.formVisible = false
              this.confirmLoading = false
            })
        } else {
          return false;
        }
      });
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
    }
  }
}
</script>

<style scoped>
  .full-width {
    width: 100%;
  }
</style>
